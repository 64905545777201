import { loggerInit } from '$lib/logger';
import type { HandleClientError } from '@sveltejs/kit';
import { goto } from '$app/navigation';

loggerInit();

export const handleError: HandleClientError = async ({ status }) => {
	if (status === 404) {
		goto('/');
	}
};
